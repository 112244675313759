export default [
  {
    path: '/',
    name: 'Index',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/caption',
    name: 'DocumentCaption',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/document-caption.vue'),
  },
  {
    path: '/greeting',
    name: 'Greeting',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/greeting.vue'),
  },
  {
    path: '/lessons',
    name: 'Lessons',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/lessons.vue'),
  },
  {
    path: '/images',
    name: 'Images',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/images.vue'),
  },
  {
    path: '/regrets',
    name: 'Regrets',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/regrets.vue'),
  },
  {
    path: '/blessings',
    name: 'Blessings',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/blessings.vue'),
  },
  {
    path: '/closing',
    name: 'Closing',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/closing.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    meta: {
      layout: 'default',
    },
    component: () => import('@/views/success.vue'),
  },
]
