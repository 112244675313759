<template>
  <header class="header">
    <div class="header__center">
      <div class="header__logo">
        <router-link :to="{ name: 'Index' }">
          <img src="@/assets/img/logo.svg" alt="logo" />
        </router-link>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Navigation',
}
</script>
