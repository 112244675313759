<template>
  <footer class="footer">
    <div class="footer__logo">
      <p><a href="https://www.odysseycp.com" target="_blank">By Odyssey Partners</a></p>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      activeStep: 1,
      steps: [
        {
          number: 'one',
          pageName: 'Index',
        },
        {
          number: 'two',
          pageName: 'Index',
        },
        {
          number: 'three',
          pageName: 'Index',
        },
        {
          number: 'four',
          pageName: 'Index',
        },
        {
          number: 'five',
          pageName: 'Index',
        },
        {
          number: 'six',
          pageName: 'Index',
        },
        {
          number: 'seven',
          pageName: 'Index',
        },
        {
          number: 'eight',
          pageName: 'Index',
        },
      ],
    }
  },
}
</script>
